import detectIOS from './detect-ios.js';

(function iosImgCheck() {
	let exceptions = window.location.pathname.search('blog') === 1 ||
			window.location.pathname.search('academy') === 1 ||
			window.location.pathname.search('price/') === 1 ||
			window.location.pathname.search('price-prediction') === 1 ||
			window.location.pathname.search('-wallet');
	if(exceptions) {
		return;
	}
	if (!detectIOS()) return;
	console.log('passed')
	
	document.addEventListener('DOMContentLoaded', function() {
		document.querySelectorAll('img[src$=".webp"], img[data-src$=".webp"]').forEach(img => {
			//src
			let src = img.getAttribute('src');
			if (src) {
				img.setAttribute('src', src.replace('.webp', '-ios.png'));
			}

			// data-src
			let dataSrc = img.getAttribute('data-src');
			if (dataSrc) {
				img.setAttribute('data-src', dataSrc.replace('.webp', '-ios.png'));
			}
		});
	})
})();
