import Cookies from 'js-cookie'
const newsletter = document.querySelector('#newsletter-popup')
const NEWSLETTER_COOKIES_TIME = 30 // in days

if (newsletter) {
	let newsletterCloseBtn = newsletter.querySelector('.newsletter-popup__close')
	let newsletter_start = newsletter.querySelector('.newsletter-popup__start')
	let newsletter_finish = newsletter.querySelector('.newsletter-popup__finish')

	if ( !Cookies.get('newsletter') ) {

		let trigger = document.querySelector('#newsletter-trigger').offsetTop - window.innerHeight/2
		window.addEventListener('scroll',()=>{
			if(window.scrollY>trigger){
				newsletter.classList.add('fadeIn')
			}
		})
	}
	newsletterCloseBtn.addEventListener('click',()=>{
		closeNewsletterSubscribe()
	})
	function closeNewsletterSubscribe(){
		Cookies.set('newsletter', 'isClosed', {
			expires: NEWSLETTER_COOKIES_TIME
		})
		newsletter.classList.add('close')
		console.log('closeNewsletterSubscribe')
	}
	function newsletterNextStep(){
		console.log('newsletterNextStep')
		newsletter_start.classList.add('newsletter-popup--fadeout')
		setTimeout(() => {
			newsletter_finish.classList.add('newsletter-popup--fadein')
		}, 100)
	}
}