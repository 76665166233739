import Swiper, { Navigation, Pagination, Lazy } from 'swiper'
Swiper.use([Navigation, Pagination, Lazy])
import 'swiper/swiper-bundle.css'

document.querySelectorAll('.slider-style').forEach((slider) => {
    new Swiper(slider.querySelector('.swiper-container'), {
        // Optional parameters
        updateOnWindowResize:true,
        touchStartPreventDefault: false,
        observer: true,
        observeParents: true,
        loop: false,
        slidesPerView: 2,
        spaceBetween: 32,
        preloadImages: false,
        lazy: {
            loadPrevNext: false,
        },
        pagination: {
            el: slider.querySelector('.swiper-pagination'),
            clickable: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: slider.querySelector('.swiper-button-next'),
            prevEl: slider.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    })
})