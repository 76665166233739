let videoCards = document.querySelectorAll('.video-card')

function bgImgLazyLoader() {
	videoCards.forEach(item => {
		let style = item.getAttribute('data-style')
		if (!item.style[0]) {
			item.setAttribute('style', style)
		}
	})
}
if (videoCards.length && videoCards[0].getAttribute('data-first') !== "true") {
	let trigger = videoCards[0].parentNode.offsetTop - window.innerHeight
	window.addEventListener('scroll',()=>{
		if(window.scrollY>trigger){
			bgImgLazyLoader()
		}
	})
} else if (videoCards.length && videoCards[0].getAttribute('data-first') === "true") {
	bgImgLazyLoader()
}

videoCards.forEach((card) => {
	card.addEventListener('click', function () {
		console.log('main')
		const href = this.dataset.video
		this.classList.add('videoIsLoaded')
		let iframe = document.createElement('iframe')
		iframe.setAttribute('allowfullscreen', '')
		iframe.setAttribute('frameborder', '0')
		iframe.setAttribute('allow', 'autoplay')
		iframe.setAttribute(
			'src',
			'https://www.youtube.com/embed/' + href + '?autoplay=1'
		)
		this.innerHTML = ''
		this.appendChild(iframe)
	})
})