import "/src/scripts/rebuild/ios-img-check.js";
import "/src/_includes/components/footer/scripts/index.js";
import "/src/_includes/components/header/scripts/index.js";
import "/src/_includes/components/popup/scripts/index.js";
import lozad from 'lozad';

// lazyload "lozad" library instantiating
const observer = lozad('.lozad', {
	// load: function(el) {
	// 	el.src = el.dataset.src;
	// 	el.onload = function () {
	// 		el.classList.add('lozad-fade');
	// 	}
	// },
	threshold: 0.1
})
observer.observe()
// window.addEventListener('load', function() {
// 	const observer = lozad()
// 	observer.observe()
// })

//animated anchor links lazy items (images, iframes) preloading
function addAnchorScrolling() {
	document.addEventListener("click", async function (e) {
		if (e.target.tagName !== "A") return;
		if (!e.target.getAttribute("href").includes("#")) return;
	
		e.preventDefault();
		const id = e.target.getAttribute("href");
		const marker = document.querySelector(id);
		//element to forced download
		const lazy = Array.from(
			document.querySelectorAll(`[data-src], ${id}, [data-srcset]`)
		);
		const lazyBeforeTarget = lazy.slice(0, lazy.indexOf(marker));
	
		//images to check before scrolling
		const images = Array
			.from(document.querySelectorAll(`img, ${id}`))
			.filter(el => !el.closest('.for-mobile, .for-tablet, .mbc-calculator-converter'))
		const imagesBeforeTarget = images.slice(0, images.indexOf(marker));
	
		lazyBeforeTarget.forEach((item) => {
			const src = item.getAttribute("data-src");
			const srcset = item.getAttribute("data-srcset");
	
			if (src) item.src = src;
			if (srcset) item.srcset = srcset;
		});
	
		const yOffset = -79;
		const element = document.querySelector(id);
	
		//checking if all images are loaded
		await new Promise((resolve) => {
			function checker() {
				let loadedCount = 0;
	
				imagesBeforeTarget.forEach((item) => {
					if (item.naturalHeight > 0) loadedCount++;
				});
				if (loadedCount === imagesBeforeTarget.length) return resolve();
	
				setTimeout(checker, 50);
			}
	
			//second setTimeout to let DOM reflow
			setTimeout(() => {
				checker();
			}, 200);
		});
		window.scrollTo({
			top: element.getBoundingClientRect().top + window.scrollY + yOffset,
			behavior: "smooth",
		});
	});
}

try {
	requestIdleCallback(addAnchorScrolling)
} catch(err) {
	setTimeout(addAnchorScrolling, 50)
}