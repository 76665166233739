import redirectLink from '/src/scripts/rebuild/redirect-hidden-link.js'

const footerColumns = document.querySelectorAll('.footer-subcol')
footerColumns.forEach( item => {
	let title = item.querySelector('.footer-title')
	title.addEventListener('click',function(){
		footerColumns.forEach((elem) => {
			if( elem == item ){
				item.classList.toggle('open')
			}else{
				elem.classList.remove('open')
			}
		})
	})
})

const widget = document.querySelector('.support-form-link')
const initBeakon = _ => {
	(function(e,t,n){
		function a(){
			var e=t.getElementsByTagName("script")[0],n=t.createElement("script");
			n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)
		}
		if(e.Beacon=n=function(t,n,a){
			e.Beacon.readyQueue.push({method:t,options:n,data:a})
		},n.readyQueue=[],"complete"===t.readyState)return a();
		a()
	})(window,document,window.Beacon||function(){});

	const run = `
		window.Beacon('init', 'e947e766-402f-44b0-8f02-3ed79a72263c')
		Beacon('open')
		Beacon('on', 'ready', () => document.querySelector('.support-form-link').remove())
		`
	const script = document.createElement('script')
	script.innerHTML = run
	document.body.insertBefore(script, null)
};

if (widget) widget.addEventListener('click', _ => {
	const button = document.querySelector('.support-form-link')
	button.classList.add('loading')
	initBeakon()
})
redirectLink()