import "/src/scripts/rebuild/accordion-menu.js";

const burgers = document.querySelectorAll(".burger");
const header = document.getElementById("header");
burgers.forEach((burger) => {
	burger.addEventListener("click", () => {
		header.classList.toggle("open");
	});
});

const headerDropdowns = document.querySelectorAll(".dropdown");
headerDropdowns.forEach((item) => {
	item.addEventListener("click", function () {
		headerDropdowns.forEach((elem) => {
			if (elem == item) {
				item.classList.toggle("open");
			} else {
				elem.classList.remove("open");
			}
		});
	});
});

//language menu
const langs = document.querySelectorAll(".dropdown__lang");

langs.forEach(item =>
	item.addEventListener("click", function() {
		const thisPageLang = document.querySelector('.dropdown__value[data-lang]').getAttribute('data-lang')
		const { origin, pathname } = window.location
		const langRegExp = new RegExp(`^\/${thisPageLang}`)
		const req = thisPageLang === 'en' ? pathname : pathname.replace(langRegExp, '')
		const reqLang = ('/' + this.querySelector('[data-lang]').getAttribute('data-lang')).replace('/en', '')

		window.location.replace(origin + reqLang + req)
	})
);