import getData from './get-data';
import * as staking from '/src/__data-store/pages/staking.json'


const PERCENTS = staking.default.supported
// const LOCALE = '/' + document.querySelector('html').getAttribute('lang');

//list of coins that will be used for table
const coinNames = [
	'ETH',
	'ADA',
	'SOL',
	'MATIC',
	'ATOM',
	'HBAR',
	'KAVA',
	'LUNC',
	'ZIL',
	'OSMO'
];

async function renderPriceList() {
	//get data
	const coinsData = await getData(coinNames);
	//find container for inserting a table
	const container = document.querySelector('.price__body');
	//get localization for stake btn
	const btnWord = container.getAttribute("data-vocabulary");

	for(let coin of coinNames) {
		//check if there is data fo coin
		if (!coinsData[coin]) {
			console.error(`Can not read data for ${coin} in price section`)
			continue
		}	
		//obj with current coin data
		const dataObj = {
			short: coinsData[coin].short,
			cap: fnum(coinsData[coin].marketCap),
			price: fnum(coinsData[coin].price),
			fullName: coinsData[coin].fullName,
			percent: getPercent(coin),
			number: coinNames.indexOf(coin) + 1,
			change: function() {
				if (coinsData[coin].change24.toString()[0] === '-') {
					return coinsData[coin].change24.toFixed(2);
				} else {
					return '+' + coinsData[coin].change24.toFixed(2)
				}
			},
			changePlus: function() {
				return coinsData[coin].change24.toString()[0] !== '-' ? true : false
			}
		}

		//create html for every coin in table
		appendItem(dataObj)
	}
	console.log(getPercent('OSMO'));

	//function for getting percent of reward for coin
	function getPercent(coin){
		let result;
		PERCENTS.forEach(item => {
			if(item.key == coin.toLowerCase()){
				result = item.percent
			}
		})
		return result;
	}
	//function to abbreviate large nums
	function fnum(x) {
		if (isNaN(x)) 
			return x
	
		if (x < 9999) {
			let i = 100,
				res = (parseInt(x * i)) / i
	
			while (!parseFloat(res) && i < 10000000) {
				i *= 10
				res = (parseInt(x * i)) / i
			}
	
			return res
		}
		if (x < 1000000) {
			return (x / 1000).toFixed(2) + 'K'
		}
		if (x < 10000000) {
			return (x / 1000000).toFixed(2) + 'M'
		}
		if (x < 1000000000000) {
			return (x / 1000000000).toFixed(2) + 'B'
		}
		if (x < 1000000000000000) {
			return (x / 1000000000).toFixed(2) + 'T'
		}
	
		return '1T+'
	}
	//function to append html for coin into table
	function appendItem(data) {
		let template = `<p class="price__number">${data.number}</p>
				<div class="price__info">
					<i class="icon icon-${data.short.toLowerCase()}"></i>
					<div class="price__row price__row--left">
						<p class="price__name">${data.fullName}</p>
						<p class="price__short">${data.short}</p>
					</div>
				</div>
				
				<div class="price__row price__row--right">
					<p class="price__apy price__apy-td">+${data.percent}%</p>
					<p class="price__cap">$${data.cap}</p>
					<p class="price__sum">$${data.price}</p>
					<p class="price__change">${data.change()}%</p>
				</div>
				<div class="price__button-wrap">
					<button class="btn-medium btn-blue">${ btnWord }</button>
				</div>`
		let newItem = document.createElement('a');
		newItem.innerHTML = template;
		newItem.classList = 'price__item';
		newItem.style.order = coinNames.indexOf(data.short);
		newItem.setAttribute('target', '_blank');

		if (data.changePlus()) {
			newItem.classList.add('price--green')
		}

		// let linkName = data.fullName;
		// newItem.href = item.data.links.wallet;

		//generate staking link
		const stakingExceptions = ['ATOM','HBAR','KAVA'];
		const stakingLink = `${data.fullName.toLowerCase().replace(/ /g, '-')}-staking`;
		const stakingExceptLink = `${data.fullName.toLowerCase().replace(/ /g, '-')}-${data.short.toLowerCase()}-staking`; 
		newItem.href = stakingExceptions.indexOf(data.short.toUpperCase()) ? stakingExceptLink : stakingLink;
	
		if (container) container.appendChild(newItem);
	}
	console.log(getPercent('BTC'));
}

try {
	requestIdleCallback(renderPriceList)
} catch(err) {
	setTimeout(renderPriceList, 50)
}