import addLastVideo from "../../../../scripts/rebuild/add-last-video";
import redirectLink from '/src/scripts/rebuild/redirect-hidden-link.js'

const newsletter = document.querySelector('#newsletter-popup')
const newsletter_start = document.querySelector('.newsletter-popup__start')
const newsletter_finish = document.querySelector('.newsletter-popup__finish')

function newsletterNextStep(){
	console.log('newsletterNextStep')
	newsletter_start.classList.add('newsletter-popup--fadeout')
	setTimeout(() => {
		newsletter_finish.classList.add('newsletter-popup--fadein')
	}, 100)
}

let popupImgs = document.querySelectorAll(`#popup-thanks .img-hidden`);
function openPopupThanks(){
	let iframe = document.querySelector('#latest-video iframe')
	addLastVideo(iframe)
	redirectLink()
	popupImgs.forEach(el => {
		el.classList.remove('img-hidden')
	})
	document.querySelector('#popup-thanks').classList.add('open')
	console.log('openPopupThanks')
}
function closePopupNotification() {
	document.querySelector('#popup-notification').classList.remove('open')
	console.log('closePopupNotification')
}
function closePopupSubscribe(){
	document.querySelector('#popup-subscribe').classList.remove('open')
	console.log('closePopupSubscribe')
}
function closePopupForm(){
	document.querySelector('#popup-form').classList.remove('open')
	console.log('closePopupForm')
}
function openPopupSubscribe(){
	document.querySelector('#popup-subscribe').classList.add('open')
	console.log('openPopupSubscribe')
}
const isPageLangFr = (document.documentElement.lang).includes('fr');
const isUserLangFr = navigator.languages.indexOf('fr') === -1  ? false : true;
const isMainPage = window.location.pathname === '/' || window.location.pathname === '/fr';
// const isFr = isPageLangFr || isUserLangFr;
// if(isFr) {
// 	openPopupUnavailable()
// }
function openPopupUnavailable() {
	let popup = document.querySelector('#popup-unavailable');
	popup.classList.add('open');
}
// function closePopupForm(){
// 	document.querySelector('#popup-form').classList.remove('open')
// 	console.log('closePopupForm')
// }

document.querySelectorAll('.subscribe-form, .news-form__form').forEach(item => {
	item.addEventListener('submit', async function(e) {
	e.preventDefault()
	const thisKey = this.getAttribute('data-key')
	const formData = new FormData(this)
	let data = {};

	formData.forEach((value, key) => data[key] = value);
	data = JSON.stringify(data);

	try {
		let res = await fetch('/form/subscribe', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: data
		})

		if( res.error ) {
			alert('Please, enter your email correctly.')
		} else {
			switch (thisKey) {
				case 'SUBSCRIBE_SECTION_KEY':
					openPopupThanks()
					break
				case 'SUBSCRIBE_POPUP_KEY':
					closePopupForm()
					closePopupSubscribe()
					openPopupThanks()
					break
				case 'SUBSCRIBE_NOTIFICATION_KEY':
					closePopupNotification()
					openPopupThanks()
					break
				case 'SUBSCRIBE_NEWSLETTER_KEY':
					if (newsletter) newsletterNextStep()
					break
			}
		}
	} catch(err) {
		alert('Please, enter your email correctly.')
		console.log('error:', err)
	}
	
})})
document.querySelectorAll('[data-download-link]').forEach(item => item.addEventListener('click', function() {
	openPopupSubscribe()
}))
let popups = document.querySelectorAll('.modal-overlay')
let buttons = document.querySelectorAll('[data-popup]')
buttons.forEach( btn => {
	btn.addEventListener('click',()=>{
		let popupName = btn.dataset.popup
		popups.forEach( popup => {
			popup.id == popupName? popup.classList.add('open') : false
			console.log(popup.id)
			document.querySelectorAll(`#${popup.id} .img-hidden`).forEach(el => {
				el.classList.remove('img-hidden')
			})
		})
	})
})
document.querySelectorAll('.modal-window__close').forEach( btnClose => {
	btnClose.addEventListener('click',()=>{
		btnClose.closest('.modal-overlay').classList.remove('open')
	})
})
popups.forEach( popup => {
	if(popup.getAttribute('id') !== 'popup-unavailable') {
		popup.addEventListener('click', e=>{
			if(e.target.classList.contains('modal-overlay')){
				popup.classList.remove('open')
				let video = popup.querySelector('#popup-video .modal-window .video-card')
				video? popup.querySelector('#popup-video .modal-window .video-card').innerHTML = '' : false
			}
		})
	}
})
document.querySelectorAll('.watch-video-button, .newsletter-popup__button, .staking-btn, .video-button').forEach(btn => {
	btn.addEventListener('click', function() {
		const href = this.dataset.video
		let iframe = document.createElement( 'iframe' )
		iframe.setAttribute( 'allowfullscreen', '' )
		iframe.setAttribute( 'frameborder', '0' )
		iframe.setAttribute( 'allow', 'autoplay' )
		iframe.setAttribute( 'src', 'https://www.youtube.com/embed/'+href+'?autoplay=1' )
		document.querySelector('#popup-video .modal-window .video-card').appendChild( iframe )
	})
})
