import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

const splide = new Splide( '.reviews-slider .splide', {
    updateOnMove: true,
    loop: false,
    rewind: true,
    rewindByDrag: true,
    perPage: 2,
    gap: 32,
    classes: {
        pagination: 'splide__pagination slider-style__dots',
		page: 'splide__pagination__page slider-style__dot',
        // arrows: 'splide__arrows slider-style__arrows',
		// arrow : 'splide__arrow slider-style__arrow',
		// prev  : 'splide__arrow--prev slider-style__prev-arrow',
		// next  : 'splide__arrow--next slider-style__next-arrow',
    },
    mediaQuery: 'min',
    breakpoints: {
		320: {
            perPage: 1,
        },
        768: {
            perPage: 2,
        },
        1200: {
            perPage: 3,
        },
  }
});
splide.mount();