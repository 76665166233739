const accordions = document.querySelectorAll('.accordion')
if (accordions.length) {
    accordions.forEach(accordion => {
        accordion.addEventListener('click', (event) => {
            const target = event.target,
                targetContainer = target.closest('.accordionContainer'),
                targetHeader = target.closest('.accordionHeader'),
                targetContainers = [...accordion.children]

            if (targetContainer && targetHeader) {
                targetContainer.classList.toggle('active')

                targetContainers.forEach((elem) => {
                    if (elem != targetContainer) {
                        elem.classList.remove('active')
                    }
                })
            }
        })
    })
}