export default async function(iframeBox) {
    const id = 'V9Igzp9K37k'
    const iframe = document.createElement('iframe')

    iframeBox.appendChild(iframe)
    iframe.setAttribute("title", "One of our videos on YouTube")
    iframe.style.cssText = "width:100%;height:100%;position:absolute;top:0;left:0;"
    iframe.setAttribute("vnum", '0')
    iframe.setAttribute("cid", 'UCLMnUt6BBtA67eic1vRGF3g')
    iframe.setAttribute("width", '600')
    iframe.setAttribute("height", '340')
    iframe.setAttribute("frameborder", '0')
    iframe.setAttribute("src", "https://youtube.com/embed/" + id + "?controls=0&autoplay=1&enablejsapi=1");
    iframe.setAttribute("srcdoc", `<style>html,body{width:100%;overflow:hidden;margin:0}</style><a style='width:100%' href='https://www.youtube.com/embed/${id}?autoplay=1&enablejsapi=1'><img style='width:100%;' src='//i2.ytimg.com/vi_webp/${id}/maxresdefault.webp'><img style='position: absolute;width:46px;right: 50%;top:50%;transform:translate(50%,-50%)' src='/images/youtube_social_icon_red.svg'></a>`);
}