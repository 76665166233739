export default function() {
    const buttons = document.querySelectorAll("[data-download-link]");
    buttons.forEach((button) => {
        button.addEventListener("click", async function () {
            try {
                const requestedFile = this.getAttribute("data-download-link");
                const downloadRes = await fetch(requestedFile);
                const downloadLink = await downloadRes.text();

                window.location.assign(downloadLink);
            } catch (err) {
                console.log(err);
            }
        });
    });
}