import Swiper, { Navigation, Pagination } from 'swiper'
import detectIOS from "/src/scripts/rebuild/detect-ios.js"
Swiper.use([Navigation, Pagination])


const isIOS = detectIOS()
let bannerLink = document.querySelector('.banner-os')
if(isIOS && bannerLink) {
	bannerLink.setAttribute('href', 'https://apps.apple.com/us/app/atomic-wallet/id1478257827')
}


let banners = document.querySelector('.latest-section__banners')
function slideBanners() {
	new Swiper(banners.querySelector('.swiper-container'), {
		// Optional parameters
		updateOnWindowResize:true,
		observer: true,
		observeParents: true,
		// loop: true,
		slidesPerView: 2,
		slidesPerColumn: 2,
		// spaceBetween: 30,
		preloadImages: false,
		pagination: {
			el: banners.querySelector('.swiper-pagination'),
			clickable: true
		},
		navigation: {
			nextEl: banners.querySelector('.swiper-button-next'),
			prevEl: banners.querySelector('.swiper-button-prev'),
		},
		breakpoints: {
			// 320: {
			//     slidesPerView: 1,
			// },
			740: {
				slidesPerView: 2,
			},
			320: {
				slidesPerView: 1,
				centeredSlides: true
			},
		},
	})
}

if(window.matchMedia('(max-width: 1000px)').matches && banners ) {
	slideBanners()
}
