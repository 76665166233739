import "/src/_includes/layout/base/scripts/index.js";
import "/src/scripts/rebuild/slider.js";
import "/src/_includes/sections/team-section/scripts/index.js";
import "/src/_includes/components/newsletter-popup/scripts/index.js";
import "/src/_includes/sections/first-section-template/scripts/index.js";
import "/src/_includes/sections/questions-section/scripts/index.js";
import "/src/_includes/sections/reviews-section/scripts/index.js";
import "/src/_includes/components/promo-banner/scripts/index.js";
import "/src/_includes/sections/prices-section/scripts/index.js";
import "/src/_includes/components/video-component/scripts/index.js";
import "/src/_includes/sections/download-section/scripts/index.js";
//import "/src/_includes/components/mobile-store-banner/scripts/index.js"

import '../styles/index.scss';

console.log('Updated')