export default async function(iframe) {
    const latestURL = "https://api.rss2json.com/v1/api.json?rss_url=" + encodeURIComponent("https://www.youtube.com/feeds/videos.xml?channel_id=");
    const data = await fetch(latestURL + iframe.getAttribute('cid'));
    const dataJSON = await data.json();

    let videoNum = (iframe.getAttribute('vnum') ? +(iframe.getAttribute('vnum')) : 0);

    let link = dataJSON.items[videoNum].link;

    let id = link.substr(link.indexOf("=") + 1);

    iframe.setAttribute("src", "https://youtube.com/embed/" + id + "?controls=0&autoplay=1");
    iframe.setAttribute("srcdoc", `<style>html,body{width:100%;overflow:hidden;margin:0}</style><a style='width:100%' href='https://www.youtube.com/embed/${id}?autoplay=1'><img style='width:100%;' src='//i2.ytimg.com/vi_webp/${id}/maxresdefault.webp'><img style='position: absolute;width:46px;right: 50%;top:50%;transform:translate(50%,-50%)' src='/images/youtube_social_icon_red.svg'></a>`);
}