import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

const splide = new Splide( '.team-section__slider .splide', {
    updateOnMove: true,
    loop: true,
    perPage: 1,
    gap: 32,
    arrows: false,
    classes: {
        pagination: 'splide__pagination slider-style__dots',
		page: 'splide__pagination__page slider-style__dot',
        // arrows: 'splide__arrows slider-style__arrows',
		// arrow : 'splide__arrow slider-style__arrow',
		// prev  : 'splide__arrow--prev slider-style__prev-arrow',
		// next  : 'splide__arrow--next slider-style__next-arrow',
    },
    mediaQuery: 'min',
    breakpoints: {
        768: {
            destroy: true
        }
    }
});
splide.mount();